<template>
	<div :id="`#${id || groupkey}`" class="c-accordion-layout">
		<div class="flex justify-between items-center mb-sm">
			<slot name="title">
				<BaseH2
					v-if="title"
					tag="h2"
					class="text-primary-button"
					v-text="title"
				/>
			</slot>

			<BaseButton
				v-if="elements && elements.length > 1"
				type="secondary"
				class="hidden >=768:block"
				@click="toggleAll"
				v-text="isGroupOpen ? 'Luk alle' : 'Åbn alle'"
			/>
		</div>

		<AccordionGroup
			v-bind="{ tag, minOneOpen, maxOneOpen }"
			ref="accordionGroup"
			class="overflow-hidden rounded-md"
		>
			<div
				v-for="(item, index) in elements"
				:key="`item-${index}`"
				class="c-accordion-layout__item relative print:bg-transparent'"
				:class="
					printTarget && printTarget.index !== index && 'print:hidden'
				"
			>
				<!-- Headers -->
				<AccordionHeader
					:id="`#${item.key || 'panel-' + index}`"
					:key="`header-${_uid}-${index}`"
					:aria-controls="`panel-${_uid}-${index}`"
					:class="[
						'c-accordion-layout__header',
						'relative flex justify-between items-center',
						'px-md py-sm w-full group',
						'duration-300 ease-smooth-out',
						item.theme
							? `c-accordion-layout__item-${item.theme}`
							: '',
						{
							'bg-primary-light': !panelsOpen[index],
							'c-accordion-layout__header--active bg-primary-medium':
								panelsOpen[index],
							'border-t border-primary-light':
								index !== 0 && !item.theme,
						},
					]"
				>
					<div
						:class="[
							'flex-1 duration-300 ease-smooth-out',
							{ 'group-hover:pl-xs': !panelsOpen[index] },
						]"
					>
						<slot
							name="header"
							v-bind="{
								item,
								index,
								isActive: panelsOpen[index],
							}"
						></slot>
					</div>
					<BaseButton
						v-if="isAgenda"
						class="mr-sm print:hidden"
						:class="panelsOpen[index] ? 'visible' : 'hidden'"
						@click="printSingle(index)"
					>
						<SvgPrint class="w-16 h-24 text-white" />
					</BaseButton>

					<SvgCaret
						v-if="hasCaret"
						:class="[
							'transform w-12',
							'duration-300 ease-smooth-out',
							'print:hidden',
							{ 'rotate-90': !panelsOpen[index] },
							{ '-rotate-90': panelsOpen[index] },
						]"
					/>

					<div
						v-if="
							!panelsOpen[index] &&
							elements &&
							index !== elements.length - 1
						"
						:class="
							item.theme
								? `c-accordion-layout__seperator-${item.theme}`
								: 'c-accordion-layout__seperator'
						"
					></div>
				</AccordionHeader>

				<!-- Panels -->
				<AccordionPanel
					:id="`panel-${_uid}-${index}`"
					:ref="`panel-${index}`"
					:key="`panel-${_uid}-${index}`"
					:transition="{ name: 't-accordion-layout__panel' }"
					@change="(event) => onPanelChange(event, index)"
				>
					<div
						:class="[
							'c-accordion-layout__panel',
							'px-md pt-sm pb-md',
							'duration-300 ease-smooth-out',
							item.theme
								? `c-accordion-layout__item-${item.theme}`
								: '',
							{
								'bg-primary-light': !panelsOpen[index],
								'c-accordion-layout__panel--active bg-primary-medium':
									panelsOpen[index],
							},
						]"
					>
						<slot
							name="panel"
							v-bind="{
								item,
								index,
								isActive: panelsOpen[index],
							}"
						></slot>
					</div>
				</AccordionPanel>
				<div
					:class="
						item.theme
							? `c-accordion-layout__seperator-${item.theme}`
							: ''
					"
				></div>
			</div>
		</AccordionGroup>
	</div>
</template>

<script>
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import SvgPrint from '~/assets/svgs/print-icon.svg?inline';
export default {
	name: 'AccordionLayout',
	components: {
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		SvgCaret,
		SvgPrint,
	},
	provide: {
		headingLevel: 3,
		accordionNestingLevel: 1,
	},
	props: {
		...AccordionGroup.props,
		title: {
			type: String,
			required: false,
		},
		elements: {
			type: Array,
			default: () => [],
		},
		id: {
			type: String,
			required: false,
		},
		groupkey: {
			type: String,
			required: false,
		},
		hasCaret: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			panelsOpen: this.elements.map(() => false),
			isGroupOpen: false,
			isAgenda: this.$store.state.template === 'AgendaPage',
			printTarget: null,
		};
	},
	mounted() {
		const target = this.$route.hash;
		this.scrollToTarget(target);
		// Deep link
		if (this.$route.hash) {
			const key = target.slice(1);
			const accIndex = this.elements.findIndex(
				(item) => item.key === key
			);
			if (accIndex >= 0 && this.$refs[`panel-${accIndex}`][0]) {
				const item = this.$refs[`panel-${accIndex}`][0];
				item.open();
				this.scrollToTarget(target);
			}
		}
	},
	methods: {
		toggleAll() {
			if (this.$refs.accordionGroup) {
				const { accordionGroup: ag } = this.$refs;
				this.isGroupOpen ? ag.closeAll() : ag.openAll();
			}
		},
		openPanel(index) {
			if (this.$refs[`panel-${index}`][0]?.open) {
				this.$refs[`panel-${index}`][0].open();
			}
		},
		onPanelChange(event, index) {
			this.$set(this.panelsOpen, index, event.isOpen);
			this.$emit('change', { index, state: event.isOpen });
			this.isGroupOpen =
				this.panelsOpen.reduce(
					(acc, cur) => (cur ? acc + 1 : acc),
					0
				) >= 2;
		},
		scrollToTarget(target) {
			document.getElementById(target)?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		},
		printSingle(index = 0) {
			if (typeof window !== 'undefined') {
				this.printTarget = { index };
				this.$nextTick(() => {
					window.print();
					window.setTimeout(() => {
						this.printTarget = null;
					}, 100);
				});
			}
		},
	},
};
</script>

<style lang="postcss">
@media print {
	.c-accordion-panel {
		max-height: none !important;
		display: block !important;
	}
}
.c-accordion-layout .c-block-list__block {
	width: 100% !important;
}
.c-accordion-layout .c-block-list {
	@apply m-0 !important;
}
.c-accordion-layout__seperator {
	@apply absolute h-1 bg-primary-medium z-10;
	top: calc(100% + 1px);
	left: 0;
	width: 100%;
}
.c-accordion-layout__seperator-primary {
	@apply absolute h-1 z-10 bg-white !important;
	top: calc(100% + 1px);
	left: 112px;
	width: calc(100% - 112px * 2);
	opacity: 0.2;
}
.c-accordion-layout__seperator-secondary {
	@apply absolute h-1 z-10 bg-black !important;
	top: calc(100% + 1px);
	left: 112px;
	width: calc(100% - 112px * 2);
	opacity: 0.1;
}
.c-accordion-layout__item-primary {
	@apply bg-primary-button text-white !important;
}
.c-accordion-layout__item-secondary {
	@apply bg-secondary !important;
}
@screen >=768 {
	.c-accordion-layout__seperator,
	.c-accordion-layout__seperator-primary,
	.c-accordion-layout__seperator-secondary {
		left: 112px;
		width: calc(100% - 112px * 2);
	}
}
.c-accordion-layout .c-accordion-panel .s-rich-text h3,
.c-accordion-layout .c-accordion-panel h4.c-base-h2 {
	font-size: var(
		--theme-fontSize-h3-accordion,
		var(--theme-fontSize-h3-accordion--sm)
	) !important;
	line-height: var(
		--theme-lineHeight-h3-accordion,
		var(--theme-lineHeight-h3-accordion--sm)
	) !important;
	letter-spacing: var(
		--theme-letterSpacing-h3-accordion,
		var(--theme-letterSpacing-h3-accordion--sm)
	) !important;
}

.c-accordion-layout .c-accordion-panel .s-rich-text h4 {
	font-size: var(
		--theme-fontSize-h4-accordion,
		var(--theme-fontSize-h4-accordion--sm)
	) !important;
	line-height: var(
		--theme-lineHeight-h4-accordion,
		var(--theme-lineHeight-h4-accordion--sm)
	) !important;
	letter-spacing: var(
		--theme-letterSpacing-h4-accordion,
		var(--theme-letterSpacing-h4-accordion--sm)
	) !important;
}
.c-accordion-layout .c-accordion-panel h3,
.c-accordion-layout .c-accordion-panel h4,
.c-accordion-layout .c-accordion-panel h5,
.c-accordion-layout .c-accordion-panel h6 {
	color: #000 !important;
}
.c-accordion-layout .c-accordion-panel h5 {
	@apply font-semibold !important;
}
.c-accordion-layout .c-content-card {
	@apply bg-white !important;
}
.t-accordion-layout__panel-enter-active,
.t-accordion-layout__panel-leave-active {
	transition: max-height 300ms cubic-bezier(0.5, 0.035, 0.19, 1);
}
.t-accordion-layout__panel-enter,
.t-accordion-layout__panel-leave-to {
	max-height: 0;
}
</style>
