var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-accordion-layout",attrs:{"id":("#" + (_vm.id || _vm.groupkey))}},[_c('div',{staticClass:"flex justify-between items-center mb-sm"},[_vm._t("title",function(){return [(_vm.title)?_c('BaseH2',{staticClass:"text-primary-button",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e()]}),_vm._v(" "),(_vm.elements && _vm.elements.length > 1)?_c('BaseButton',{staticClass:"hidden >=768:block",attrs:{"type":"secondary"},domProps:{"textContent":_vm._s(_vm.isGroupOpen ? 'Luk alle' : 'Åbn alle')},on:{"click":_vm.toggleAll}}):_vm._e()],2),_vm._v(" "),_c('AccordionGroup',_vm._b({ref:"accordionGroup",staticClass:"overflow-hidden rounded-md"},'AccordionGroup',{ tag: _vm.tag, minOneOpen: _vm.minOneOpen, maxOneOpen: _vm.maxOneOpen },false),_vm._l((_vm.elements),function(item,index){return _c('div',{key:("item-" + index),staticClass:"c-accordion-layout__item relative print:bg-transparent'",class:_vm.printTarget && _vm.printTarget.index !== index && 'print:hidden'},[_c('AccordionHeader',{key:("header-" + _vm._uid + "-" + index),class:[
					'c-accordion-layout__header',
					'relative flex justify-between items-center',
					'px-md py-sm w-full group',
					'duration-300 ease-smooth-out',
					item.theme
						? ("c-accordion-layout__item-" + (item.theme))
						: '',
					{
						'bg-primary-light': !_vm.panelsOpen[index],
						'c-accordion-layout__header--active bg-primary-medium':
							_vm.panelsOpen[index],
						'border-t border-primary-light':
							index !== 0 && !item.theme,
					} ],attrs:{"id":("#" + (item.key || 'panel-' + index)),"aria-controls":("panel-" + _vm._uid + "-" + index)}},[_c('div',{class:[
						'flex-1 duration-300 ease-smooth-out',
						{ 'group-hover:pl-xs': !_vm.panelsOpen[index] } ]},[_vm._t("header",null,null,{
							item: item,
							index: index,
							isActive: _vm.panelsOpen[index],
						})],2),_vm._v(" "),(_vm.isAgenda)?_c('BaseButton',{staticClass:"mr-sm print:hidden",class:_vm.panelsOpen[index] ? 'visible' : 'hidden',on:{"click":function($event){return _vm.printSingle(index)}}},[_c('SvgPrint',{staticClass:"w-16 h-24 text-white"})],1):_vm._e(),_vm._v(" "),(_vm.hasCaret)?_c('SvgCaret',{class:[
						'transform w-12',
						'duration-300 ease-smooth-out',
						'print:hidden',
						{ 'rotate-90': !_vm.panelsOpen[index] },
						{ '-rotate-90': _vm.panelsOpen[index] } ]}):_vm._e(),_vm._v(" "),(
						!_vm.panelsOpen[index] &&
						_vm.elements &&
						index !== _vm.elements.length - 1
					)?_c('div',{class:item.theme
							? ("c-accordion-layout__seperator-" + (item.theme))
							: 'c-accordion-layout__seperator'}):_vm._e()],1),_vm._v(" "),_c('AccordionPanel',{key:("panel-" + _vm._uid + "-" + index),ref:("panel-" + index),refInFor:true,attrs:{"id":("panel-" + _vm._uid + "-" + index),"transition":{ name: 't-accordion-layout__panel' }},on:{"change":function (event) { return _vm.onPanelChange(event, index); }}},[_c('div',{class:[
						'c-accordion-layout__panel',
						'px-md pt-sm pb-md',
						'duration-300 ease-smooth-out',
						item.theme
							? ("c-accordion-layout__item-" + (item.theme))
							: '',
						{
							'bg-primary-light': !_vm.panelsOpen[index],
							'c-accordion-layout__panel--active bg-primary-medium':
								_vm.panelsOpen[index],
						} ]},[_vm._t("panel",null,null,{
							item: item,
							index: index,
							isActive: _vm.panelsOpen[index],
						})],2)]),_vm._v(" "),_c('div',{class:item.theme
						? ("c-accordion-layout__seperator-" + (item.theme))
						: ''})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }